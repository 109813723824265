import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { ReactComponent as DoneIcon } from 'assets/imgs/airdrop/done.svg'
import { ClaimButton } from 'components/Button'
import { RowBetween } from 'components/Row'
import { useActiveChainId } from 'connection/useActiveChainId'
import { AIRDROP_API } from 'constants/misc'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

import { CampaignPage } from '../Campaign'

const StyledActivityBox = styled(Box)`
  width: 566px;
  max-width: 100%;
  padding: 0 24px;
  background: ${({ theme }) => theme.airdropBg};
  border-radius: 16px;
  .activityItem {
    padding: 24px 0;
    &:not(:last-child) {
      border-bottom: 1px dashed ${({ theme }) => theme.sectionBorder};
    }
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 16px;
    width: 90vw;
  }
`

export default function AirdropActivities({ discordUser, twitterUser, basicPoints, handlerGenerate }: any) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const { account } = useActiveChainId()

  const { openConnectModal } = useConnectModal()
  const handlerConnect = () => {
    if (!openConnectModal) return
    openConnectModal()
  }

  const twitter_url = `${AIRDROP_API}/auth/twitter`

  const discord_url = `${AIRDROP_API}/auth/discord`

  return (
    <CampaignPage data-testid="airdrop-activities-page">
      <StyledActivityBox>
        <RowBetween className="activityItem" gap="8px">
          <Box display="flex" alignItems="center" gap={isSmallScreen ? '8px' : '16px'} className="flex-1">
            <ThemedText.TextSecondary
              fontSize={isSmallScreen ? 16 : 36}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.54)' }}
            >
              01
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary
              fontSize={isSmallScreen ? 12 : 14}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.87)' }}
            >
              <Trans>Twitter Verification follow</Trans>
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontSize={isSmallScreen ? 12 : 14} fontWeight={700} color="primaryBtn">
              &nbsp;@Roguex_io
            </ThemedText.TextPrimary>
          </Box>
          {twitterUser ? (
            <DoneIcon />
          ) : (
            <a href={twitter_url} target="_self" rel="noreferrer" style={{ textDecoration: 'none' }}>
              <ClaimButton
                height="40px"
                width={isSmallScreen ? '80px' : '134px'}
                fontSize={isSmallScreen ? '14px' : '16px'}
              >
                CONNECT
              </ClaimButton>
            </a>
          )}
        </RowBetween>
        <RowBetween className="activityItem" gap="8px">
          <Box display="flex" alignItems="center" gap={isSmallScreen ? '8px' : '16px'} className="flex-1">
            <ThemedText.TextSecondary
              fontSize={isSmallScreen ? 16 : 36}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.54)' }}
            >
              02
            </ThemedText.TextSecondary>
            <Box display="flex" alignItems="center">
              <ThemedText.TextPrimary
                fontSize={isSmallScreen ? 12 : 14}
                fontWeight={700}
                style={{ color: 'rgba(255, 255, 255, 0.87)' }}
              >
                <Trans>Join</Trans>
                <span style={{ color: '#50C0FF' }}>&nbsp;Roguex Discord</span>
              </ThemedText.TextPrimary>
            </Box>
          </Box>
          {discordUser ? (
            <DoneIcon />
          ) : (
            <a href={discord_url} target="_self" rel="noreferrer" style={{ textDecoration: 'none' }}>
              <ClaimButton
                height="40px"
                width={isSmallScreen ? '80px' : '134px'}
                fontSize={isSmallScreen ? '14px' : '16px'}
                disabled={twitterUser ? false : true}
              >
                CONNECT
              </ClaimButton>
            </a>
          )}
        </RowBetween>
        <RowBetween className="activityItem" gap="8px">
          <Box display="flex" alignItems="center" gap={isSmallScreen ? '8px' : '16px'} className="flex-1">
            <ThemedText.TextSecondary
              fontSize={isSmallScreen ? 16 : 36}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.54)' }}
            >
              03
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary
              fontSize={isSmallScreen ? 12 : 14}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.87)' }}
            >
              <Trans>Identification of basic points</Trans>
            </ThemedText.TextPrimary>
          </Box>
          <ClaimButton
            height="40px"
            width={isSmallScreen ? '80px' : '134px'}
            fontSize={isSmallScreen ? '14px' : '16px'}
            disabled={twitterUser && discordUser ? false : true}
          >
            CONNECT
          </ClaimButton>
        </RowBetween>
        <RowBetween className="activityItem" gap="8px">
          <Box display="flex" alignItems="center" gap={isSmallScreen ? '8px' : '16px'} className="flex-1">
            <ThemedText.TextSecondary
              fontSize={isSmallScreen ? 16 : 36}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.54)' }}
            >
              04
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary
              fontSize={isSmallScreen ? 12 : 14}
              fontWeight={700}
              style={{ color: 'rgba(255, 255, 255, 0.87)' }}
            >
              <Trans>Generate Invitation Code</Trans>
            </ThemedText.TextPrimary>
          </Box>
          <ClaimButton
            height="40px"
            width={isSmallScreen ? '80px' : '134px'}
            fontSize={isSmallScreen ? '14px' : '16px'}
            onClick={account ? handlerGenerate : handlerConnect}
            disabled={twitterUser && discordUser && basicPoints ? false : true}
          >
            CONNECT
          </ClaimButton>
        </RowBetween>
      </StyledActivityBox>
    </CampaignPage>
  )
}
