import { Trans } from '@lingui/macro'
import { InterfacePageName } from '@uniswap/analytics-events'
import { Trace } from 'analytics'
import { AutoColumn } from 'components/Column'
import { PageWrapper } from 'components/swap/styleds'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { useActiveChainId } from 'connection/useActiveChainId'
import { isSupportedChain } from 'constants/chains'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import store from '../../state/index'
import PoolList from './PoolList'
import {
  ButtonRow,
  ErrorContainer,
  MainContentWrapper,
  NetworkIcon,
  ResponsiveButtonPrimary,
  TitleRow,
} from './StyledPool'

function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <ThemedText.LargeHeader>
                <Trans>My Pools</Trans>
              </ThemedText.LargeHeader>
            </TitleRow>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.textTertiary} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <Trans>Your connected network is unsupported.</Trans>
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}

export default function MyPools() {
  const { chainId } = useActiveChainId()
  const chainId2 = store.getState().application.chainId
  console.log({chainId2,chainId})
  if (!isSupportedChain(chainId)) {
    return <WrongNetworkCard />
  }

  return (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <ThemedText.LargeHeader>
                <Trans>My Pools</Trans>
              </ThemedText.LargeHeader>
              <ButtonRow>
                <ResponsiveButtonPrimary data-cy="join-pool-button" id="join-pool-button" as={Link} to="/add/ETH">
                  + <Trans>New Position</Trans>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </TitleRow>
            <PoolList />
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      <SwitchLocaleLink />
    </Trace>
  )
}
