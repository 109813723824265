import { Interface } from '@ethersproject/abi'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import hypervisorABI from 'abis/Hypervisor.json'
import MasterchefV3ABI from 'abis/MasterchefV3.json'
import SWAPMINGABI from 'abis/swapMinning.json'
import VOTEREWADSABI from 'abis/votingReward.json'
import BigNumber from 'bignumber.js'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useRoguxCurrency } from 'constants/tokens'
import { useLockContract, useVoter } from 'hooks/useContract'
import { IReward, useGraphTokenListMAP, useRoxPriceETH } from 'hooks/useQueryLiquidity'
import { useAtomValue } from 'jotai/utils'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useGetLockTokenIds } from 'pages/Lock/hooks'
import { useEffect, useMemo } from 'react'
import { useHttpPoolListData, useVoteCurrentId } from 'state/http/hooks'
import { BN, fromWei, toFromBN } from 'utils/bn'
import { DURATION, getCurrRound } from 'utils/CurrRound'
import { useContractReads } from 'wagmi'

import { filterStringAtom, sortAscendingAtom, sortMethodAtom, VoteSortMethod } from './state'
import { VoteDataProps } from './types'

// const VOTINGREWARD_INTERFACE = new Interface(votingRewardABI.abi)
const HYPERVISOR_INTERFACE = new Interface(hypervisorABI.abi)
const SWAPMING_INTERFACE = new Interface(SWAPMINGABI.abi)

export const useTotalVoteds = () => {
  const voteContract = useVoter()
  const currId = useVoteCurrentId()
  const { result: totalWeight } = useSingleCallResult(voteContract, 'totalWeightAt', [currId * DURATION ?? undefined])
  return useMemo(() => {
    if (!totalWeight) return '0'
    return totalWeight[0]
  }, [totalWeight])
}

export function useFetchVoter() {
  const gaugeListTemp = useHttpPoolListData()
  const { account, chainId } = useActiveChainId()
  const gaugeList = gaugeListTemp?.gauges


  const ethprice = useMemo(() => gaugeListTemp?.bundles[0]?.ethPriceUSD, [gaugeListTemp])

  const swapMingAddressList = gaugeList?.map((gauge) => {
    return gauge?.swapMinning.swapMinningAddress
  })

  const masterChefAddressList = gaugeList?.map((gauge) => {
    return gauge?.masterChef.masterChefAddress
  })
  const VotingRewardAddressList = gaugeList?.map((gauge) => {
    return gauge?.votingReward.VotingRewardAddress
  })

  const hypervisorAddress = gaugeList?.map((ga) => ga.hypervisor.hypervisorAddress)
  const rewardsLists = useMemo(() => {
    return gaugeList?.reduce<{
      voterewardsLists: { [key: string]: IReward[] }
      LiquidityrewardsLists: { [key: string]: IReward[] }
      swapMinningrewardsLists: { [key: string]: IReward[] }
    }>(
      (pre: any, curr) => {
        const obj: any = {}
        const obj2: any = {}
        const obj3: any = {}
        //  vote
        curr.votingReward.bribeReward.forEach((x) => {
          if (!obj[x.tokenInfo.id.toLocaleLowerCase()]) {
            obj[x.tokenInfo.id.toLocaleLowerCase()] = x
          } else {
            const plusAmount = BN(obj[x.tokenInfo.id.toLocaleLowerCase()].rewardAmount || 0).plus(x.rewardAmount || 0)
            const xx = {
              ...x,
              rewardAmount: plusAmount.toFixed(),
            }
            obj[x.tokenInfo.id.toLocaleLowerCase()] = xx
          }
        })
        curr.votingReward.feeReward.forEach((x) => {
          if (!obj[x.tokenInfo.id.toLocaleLowerCase()]) {
            obj[x.tokenInfo.id.toLocaleLowerCase()] = x
          } else {
            const plusAmount = BN(obj[x.tokenInfo.id.toLocaleLowerCase()].rewardAmount || 0).plus(x.rewardAmount || 0)
            const xx = {
              ...x,
              rewardAmount: plusAmount.toFixed(),
            }
            obj[x.tokenInfo.id.toLocaleLowerCase()] = xx
          }
        })
        if (!pre.voterewardsLists[curr.lpAddr]) {
          pre.voterewardsLists[curr.lpAddr] = []
        }
        pre.voterewardsLists[curr.lpAddr] = Object.values(obj)

        // liquidity
        curr.bribeReward.forEach((x) => {
          if (!obj2[x.tokenInfo.id.toLocaleLowerCase()]) {
            obj2[x.tokenInfo.id.toLocaleLowerCase()] = x
            if (!pre.LiquidityrewardsLists[curr.lpAddr]) {
              pre.LiquidityrewardsLists[curr.lpAddr] = []
            }
          } else {
            const plusAmount = BN(obj2[x.tokenInfo.id.toLocaleLowerCase()].rewardAmount || 0).plus(x.rewardAmount || 0)
            const xx = {
              ...x,
              rewardAmount: plusAmount.toFixed(),
            }

            obj2[x.tokenInfo.id.toLocaleLowerCase()] = xx
          }
        })
        curr.feeReward.forEach((x) => {
          if (!obj2[x.tokenInfo.id.toLocaleLowerCase()]) {
            obj2[x.tokenInfo.id.toLocaleLowerCase()] = x
            if (!pre.LiquidityrewardsLists[curr.lpAddr]) {
              pre.LiquidityrewardsLists[curr.lpAddr] = []
            }
            pre.LiquidityrewardsLists[curr.lpAddr].push(x)
          } else {
            const plusAmount = BN(obj2[x.tokenInfo.id.toLocaleLowerCase()].rewardAmount || 0).plus(x.rewardAmount || 0)
            const xx = {
              ...x,
              rewardAmount: plusAmount.toFixed(),
            }
            if (!pre.LiquidityrewardsLists[curr.lpAddr]) {
              pre.LiquidityrewardsLists[curr.lpAddr] = []
            }
            obj2[x.tokenInfo.id.toLocaleLowerCase()] = xx
            pre.LiquidityrewardsLists[curr.lpAddr].push(xx)
          }
        })

        if (!pre.LiquidityrewardsLists[curr.lpAddr]) {
          pre.LiquidityrewardsLists[curr.lpAddr] = []
        }
        pre.LiquidityrewardsLists[curr.lpAddr] = Object.values(obj2)

        //swapMinningAddress
        curr.swapMinning.bribeReward.forEach((x) => {
          if (!obj3[x.tokenInfo.id.toLocaleLowerCase()]) {
            obj3[x.tokenInfo.id.toLocaleLowerCase()] = x
            if (!pre.swapMinningrewardsLists[curr.lpAddr]) {
              pre.swapMinningrewardsLists[curr.lpAddr] = []
            }
            pre.swapMinningrewardsLists[curr.lpAddr].push(x)
          } else {
            const plusAmount = BN(obj3[x.tokenInfo.id.toLocaleLowerCase()].rewardAmount || 0).plus(x.rewardAmount || 0)
            const xx = {
              ...x,
              rewardAmount: plusAmount.toFixed(),
            }
            if (!pre.swapMinningrewardsLists[curr.lpAddr]) {
              pre.swapMinningrewardsLists[curr.lpAddr] = []
            }
            obj3[x.tokenInfo.id.toLocaleLowerCase()] = xx
            pre.swapMinningrewardsLists[curr.lpAddr].push(xx)
          }
        })
        curr.swapMinning.feeReward.forEach((x) => {
          if (!obj3[x.tokenInfo.id.toLocaleLowerCase()]) {
            obj3[x.tokenInfo.id.toLocaleLowerCase()] = x
          } else {
            const plusAmount = BN(obj3[x.tokenInfo.id.toLocaleLowerCase()].rewardAmount || 0).plus(x.rewardAmount || 0)
            const xx = {
              ...x,
              rewardAmount: plusAmount.toFixed(),
            }
            obj3[x.tokenInfo.id.toLocaleLowerCase()] = xx
          }
        })
        if (!pre.swapMinningrewardsLists[curr.lpAddr]) {
          pre.swapMinningrewardsLists[curr.lpAddr] = []
        }
        pre.swapMinningrewardsLists[curr.lpAddr] = Object.values(obj3)

        return pre
      },
      { voterewardsLists: {}, LiquidityrewardsLists: {}, swapMinningrewardsLists: {} }
    )
  }, [gaugeList])
  const lpStakeAmounts = useMultipleContractSingleData(
    hypervisorAddress ?? [undefined],
    HYPERVISOR_INTERFACE,
    'getUserAmounts',
    [account]
  )

  //  swapming
  const roxprice = useRoxPriceETH()
  const roxpriceTemp = !roxprice || Number(roxprice) == 0 ? 1 : Number(roxprice)
  const totalWeights = useTotalVoteds()
  const currnet = getCurrRound()

  const tokensPrice = useGraphTokenListMAP()
  const selectEpoch = useVoteCurrentId()

  const selcetTimeStamp = selectEpoch * DURATION

  const myVolumes = useMultipleContractSingleData(swapMingAddressList ?? [undefined], SWAPMING_INTERFACE, 'balanceOf', [
    account,
    currnet,
  ])

  const voteContract = useVoter()

  const { tokenIds: lockTokenIds, loading } = useGetLockTokenIds()

  const epochVotePrams = useMemo(() => {
    if (!lockTokenIds || !gaugeList) return [[undefined]]
    return gaugeList.map((x) => {
      if (!x) return [undefined]
      return [lockTokenIds, selcetTimeStamp, x.lpAddr]
    })
  }, [gaugeList, lockTokenIds, selcetTimeStamp])

  const epochVotes = useSingleContractMultipleData(voteContract, 'getEpochVotes', epochVotePrams)

  const epochVoteloading = epochVotes.some((val) => val.loading)
  // Liquidity

  const LiquidityContract: any = useMemo(() => {
    if (!masterChefAddressList) return []

    return masterChefAddressList.map((x) => {
      return {
        address: x,
        abi: MasterchefV3ABI.abi,
        functionName: 'getRewardList',
      }
    })
  }, [masterChefAddressList])

  const votetingContract: any = useMemo(() => {
    if (!VotingRewardAddressList) return []
    return VotingRewardAddressList.map((x) => {
      return {
        address: x,
        abi: MasterchefV3ABI.abi,
        functionName: 'getRewardList',
      }
    })
  }, [VotingRewardAddressList])

  const {
    data: liquidityReader,
    error,
    isError,
    isLoading,
  } = useContractReads({
    contracts: LiquidityContract,
  })
  const { data: votetingReader, error: voer } = useContractReads({
    contracts: votetingContract,
  })

  const liquidityrewardRateContact = useMemo(() => {
    if (isLoading || isError || !liquidityReader || !masterChefAddressList) return { data: [], queryslice: [] }
    const data: any = []
    const queryslice: any = []
    liquidityReader.map(({ result }: any, index) => {
      result?.map((x: any) => {
        queryslice.push({
          address: masterChefAddressList[index].toLocaleLowerCase(),
          token: x,
        })
        data.push({
          address: masterChefAddressList[index].toLocaleLowerCase(),
          abi: MasterchefV3ABI.abi,
          functionName: 'rewardRate',
          args: [x],
        })
      })
    })

    return { data, queryslice }
  }, [isError, isLoading, liquidityReader, masterChefAddressList])

  const votetingewardRateContact = useMemo(() => {
    if (isLoading || isError || !liquidityReader || !VotingRewardAddressList) return { data: [], queryslice: [] }
    const data: any = []
    const queryslice: any = []
    liquidityReader.map(({ result }: any, index) => {
      result?.map((x: any) => {
        queryslice.push({
          address: VotingRewardAddressList[index].toLocaleLowerCase(),
          token: x,
        })
        data.push({
          address: VotingRewardAddressList[index].toLocaleLowerCase(),
          abi: VOTEREWADSABI.abi,
          functionName: 'rewardRate',
          args: [x, selcetTimeStamp],
        })
      })
    })
    return { data, queryslice }
  }, [VotingRewardAddressList, isError, isLoading, liquidityReader, selcetTimeStamp])

  const totalVotes = useMemo(() => {
    let res = BN(0)
    if (epochVotes?.length != 0) {
      epochVotes.map((item) => {
        if (item.result) {
          res = res.plus(BN(item.result.allEpochVotes.toString()))
        }
      })
    }
    return res
  }, [epochVotes])
  const {
    data: liquidityrewardRate,
    isError: lqrr,
    isLoading: lqld,
  } = useContractReads({
    contracts: liquidityrewardRateContact.data,
  })

  const {
    data: votetingReaward,
    isError: isvotereaward,
    isLoading: voterloading,
  } = useContractReads({
    contracts: votetingewardRateContact.data,
  })

  const fliterliquidityrewardRate = useMemo(() => {
    if (!liquidityrewardRate || lqrr || lqld) return
    return liquidityrewardRateContact.queryslice.reduce((pre: any, curr: any, index: number) => {
      if (pre[curr.address]) {
        pre[curr.address].push({
          rate: liquidityrewardRate[index].result,
          ...curr,
        })
      } else {
        pre[curr.address] = [
          {
            rate: liquidityrewardRate[index].result,
            ...curr,
          },
        ]
      }

      return pre
    }, {})
  }, [liquidityrewardRate, liquidityrewardRateContact.queryslice, lqld, lqrr])

  const flitervotetingReaward = useMemo(() => {
    if (!votetingReaward || isvotereaward || voterloading) return

    return votetingewardRateContact.queryslice.reduce((pre: any, curr: any, index: any) => {
      if (pre[curr.address]) {
        pre[curr.address].push({
          rate: votetingReaward[index].result,
          ...curr,
        })
      } else {
        pre[curr.address] = [
          {
            rate: votetingReaward[index].result,
            ...curr,
          },
        ]
      }
      return pre
    }, {})
  }, [isvotereaward, voterloading, votetingReaward, votetingewardRateContact.queryslice])

  // weights
  const queryAll = useMemo(() => {
    if (
      !gaugeList ||
      epochVoteloading ||
      loading ||
      !fliterliquidityrewardRate ||
      !flitervotetingReaward ||
      !tokensPrice
    )
      return { voteList: undefined, voteMAP: undefined }
    return gaugeList?.reduce<{ voteList?: VoteDataProps[]; voteMAP?: { [key: string]: VoteDataProps } }>(
      (pre: any, cur, index) => {
        if (totalWeights == undefined || !chainId || myVolumes.length == 0 || epochVotes.length == 0) return pre
        const { result: myVolume } = myVolumes[index]
        const { result: lpStakeAmount } = lpStakeAmounts[index]
        const { result: epochVote } = epochVotes[index]
        if (!rewardsLists || !myVolume || !ethprice || !epochVote || !roxpriceTemp) return pre
        const rewardsList = rewardsLists.voterewardsLists[cur.lpAddr]
        const LiquidityrewardsLists = rewardsLists.LiquidityrewardsLists[cur.lpAddr]
        const swapMinningrewardsLists = rewardsLists.swapMinningrewardsLists[cur.lpAddr]
        const liquidityRewardRte = fliterliquidityrewardRate[cur.masterChef.masterChefAddress]
        const voteRewardRte = flitervotetingReaward[cur.votingReward.VotingRewardAddress]
        const liquidityRewardUsd = liquidityRewardRte.reduce((pre: BigNumber, cur: any, index: any) => {
          const tokenPirce = tokensPrice[cur.token.toLocaleLowerCase()]
          if (tokenPirce) {
            const rate = cur.rate
            const tokenapr = fromWei(rate || 0, Number(tokenPirce.decimals))
              .times(3600)
              .times(24)
              .times(365)
            const amount = tokenapr.times(tokenPirce?.derivedETH || 0).times(ethprice)
            pre = pre.plus(amount)
          }
          return pre
        }, BN(0))

        const voteRewardRteUsd = voteRewardRte.reduce((pre: BigNumber, cur: any, index: any) => {
          const tokenPirce = tokensPrice[cur.token.toLocaleLowerCase()]
          if (tokenPirce) {
            const rate = cur.rate
            const tokenapr = fromWei(rate, Number(tokenPirce.decimals)).times(3600).times(24).times(365)
            const amount = tokenapr.times(tokenPirce?.derivedETH || 0).times(ethprice)
            pre = pre.plus(amount)
          }
          return pre
        }, BN(0))

        const isZero =
          Number(totalWeights) == 0 ||
          !cur.weeklyVotedTotal[0]?.totalVoted ||
          Number(cur.weeklyVotedTotal[0]?.totalVoted) == 0
        const poolWeightRatio = isZero
          ? BN(0)
          : BN(cur.weeklyVotedTotal[0]?.totalVoted.toString()).div(Number(totalWeights)).times(100)
        const swapMinningInfo = cur.PoolVolume[0]
        const token0 = CurrencyAmount.fromRawAmount(
          new Token(chainId, cur.token0.id, Number(cur.token0.decimals), cur.token0.symbol, cur.token0.name),
          cur.hypervisor.depositedToken0Amount
        )
        const stakeToken0AmountUSD = toFromBN(lpStakeAmount?.amount0 || 0, Number(cur.token0.decimals))
          .times(cur.token0.derivedETH || 0)
          .times(ethprice)

        const stakeToken1AmountUSD = toFromBN(lpStakeAmount?.amount1 || 0, Number(cur.token1.decimals))
          .times(cur.token1.derivedETH || 0)
          .times(ethprice)

        const stakeData = {
          tvl: stakeToken0AmountUSD.plus(stakeToken1AmountUSD).toFixed(),
          amount0: toFromBN(lpStakeAmount?.amount0 || 0, Number(cur.token0.decimals)).toFixed(),
          amount1: toFromBN(lpStakeAmount?.amount1 || 0, Number(cur.token1.decimals)).toFixed(),
        }
        const voterewardsUSD = rewardsList
          ? rewardsList.reduce((pre, curr) => {
              pre = pre.plus(
                fromWei(
                  BN(curr.tokenInfo.derivedETH || 0)
                    .times(curr.rewardAmount)
                    .times(ethprice)
                    .toFixed(),
                  Number(curr.tokenInfo.decimals)
                )
              )
              return pre
            }, BN(0))
          : BN(0)

        const LiquidityrewardUSD = LiquidityrewardsLists
          ? LiquidityrewardsLists.reduce((pre, curr) => {
              pre = pre.plus(
                fromWei(
                  BN(curr.tokenInfo.derivedETH || 0)
                    .times(curr.rewardAmount)
                    .times(ethprice)
                    .toFixed(),
                  Number(curr.tokenInfo.decimals)
                )
              )
              return pre
            }, BN(0))
          : BN(0)

        const swapMinningrewardUSD = swapMinningrewardsLists
          ? swapMinningrewardsLists.reduce((pre, currx) => {
              pre = pre.plus(
                fromWei(
                  BN(currx.tokenInfo.derivedETH || 0)
                    .times(currx.rewardAmount)
                    .times(ethprice)
                    .toFixed(),
                  Number(currx.tokenInfo.decimals)
                )
              )
              return pre
            }, BN(0))
          : BN(0)

        const votetotalFeeRewardsUSD = cur.votingReward.feeReward
          ? cur.votingReward.feeReward.reduce((pre, currx) => {
              pre = pre.plus(
                fromWei(
                  BN(currx.tokenInfo.derivedETH || 0)
                    .times(currx.rewardAmount)
                    .times(ethprice)
                    .toFixed()
                ),
                Number(currx.tokenInfo.decimals)
              )
              return pre
            }, BN(0))
          : BN(0)

        const votetotalBribeRewardsUSD = cur.votingReward.bribeReward
          ? cur.votingReward.bribeReward.reduce((pre, curxr) => {
              pre = pre.plus(
                fromWei(
                  BN(curxr.tokenInfo.derivedETH || 0)
                    .times(curxr.rewardAmount)
                    .times(ethprice)
                    .toFixed(),
                  Number(curxr.tokenInfo.decimals)
                )
              )
              return pre
            }, BN(0))
          : BN(0)
        const votetotalRewardsUSD = votetotalFeeRewardsUSD.plus(votetotalBribeRewardsUSD)
        const masterChefAddress = cur.masterChef.masterChefAddress
        const hypervisorAddress = cur.hypervisor.hypervisorAddress
        const token1 = CurrencyAmount.fromRawAmount(
          new Token(chainId, cur.token1.id, Number(cur.token1.decimals), cur.token1.symbol, cur.token1.name),
          cur.hypervisor.depositedToken1Amount
        )

        const liquityAPR = liquidityRewardUsd.div(cur.hypervisor.depositedUSDAmount)
        const vApr = voteRewardRteUsd.div(
          fromWei(cur.weeklyVotedTotal[0]?.totalVoted).times(ethprice).times(roxpriceTemp)
        )
        // Number(votetotalRewardsUSD.toFixed()) == 0 ||
        // Number(cur.weeklyVotedTotal[0]?.totalVoted) == 0 ||
        // !cur.weeklyVotedTotal[0]?.totalVoted
        //   ? 0
        //   : (Number(votetotalRewardsUSD.toFixed()) * 365) /
        //     7 /
        //     ((Number(cur.weeklyVotedTotal[0]?.totalVoted) / Math.pow(10, 18)) * Number(ethprice) * roxpriceTemp)

        const arrList = {
          rewardsList,
          LiquidityrewardsLists,
          swapMinningrewardsLists,
          voterewardUSD: voterewardsUSD.toFixed(),
          LiquidityrewardUSD: LiquidityrewardUSD.toFixed(),
          swapMinningrewardUSD: swapMinningrewardUSD.toFixed(),
          votetotalRewardsUSD: votetotalRewardsUSD.toFixed(),
          votetotalBribeRewardsUSD: votetotalBribeRewardsUSD.toFixed(),
          votetotalFeeRewardsUSD: votetotalFeeRewardsUSD.toFixed(),
          ethPrice: ethprice,
          poolWeightRatio,
          token0: cur.token0,
          token1: cur.token1,
          liqvidityToken0: token0,
          liqvidityToken1: token1,
          tvl: cur.hypervisor.depositedUSDAmount,
          votingRewardAddress: cur.votingReward.VotingRewardAddress,
          poolFee: cur.poolFee,
          bribeReward: cur.votingReward.bribeReward,
          feeReward: cur.votingReward.feeReward,
          lpAddr: cur.lpAddr,
          swapMinningAddress: cur.swapMinning.swapMinningAddress,
          swapMinningInfo,
          myVolumes: myVolume[0],
          totalVolume: swapMinningInfo?.totalValume || 0,
          masterChefAddress,
          hypervisorAddress,
          stakeData,
          liquityAPR: liquityAPR.isNaN() ? 0 : liquityAPR.toFixed(2),
          vAPR: vApr.isNaN() ? 0 : vApr.toFixed(2),
          epochVote: BN(epochVote.allEpochVotes.toString()),
          epochVoteTotal: totalVotes,
          weeklyVotedTotal: cur.weeklyVotedTotal[0]?.totalVoted || 0,
        }
        if (!pre.voteList || !pre.voteMAP) {
          pre.voteList = []
          pre.voteMAP = {}
        }
        pre.voteList.push({ ...arrList })
        pre.voteMAP[cur.lpAddr] = {
          ...arrList,
        }
        return pre
      },
      { voteList: undefined, voteMAP: undefined }
    )
  }, [
    gaugeList,
    epochVoteloading,
    loading,
    fliterliquidityrewardRate,
    flitervotetingReaward,
    tokensPrice,
    totalWeights,
    chainId,
    myVolumes,
    epochVotes,
    lpStakeAmounts,
    rewardsLists,
    ethprice,
    roxpriceTemp,
    totalVotes,
  ])

  // useSharsAmount

  useEffect(() => {
    console.log('[queryAll]:', queryAll)
  }, [queryAll, rewardsLists])
  return queryAll
}

export function useVotedData(nftId?: string) {
  const lockContract = useLockContract()
  const voteContract = useVoter()
  const currId = useVoteCurrentId()
  const { result: isVoted } = useSingleCallResult(voteContract, 'isVoted', [
    nftId ?? undefined,
    currId * DURATION ?? undefined,
  ])
  const { result: balanceOfNFT } = useSingleCallResult(lockContract, 'balanceOfNFT', [nftId ?? undefined])
  const rox = useRoguxCurrency()
  return useMemo(() => {
    if (!isVoted || !balanceOfNFT || !rox) return
    return {
      nftBalance: CurrencyAmount.fromRawAmount(rox, balanceOfNFT[0]),
      isVoted: isVoted[0] as boolean,
    }
  }, [balanceOfNFT, isVoted, rox])
}

export function useVoteData() {
  const { voteList } = useFetchVoter()

  const sortMethod = useAtomValue(sortMethodAtom)
  const sortAscending = useAtomValue(sortAscendingAtom)

  const sortData = useMemo(() => {
    if (!voteList) return
    let voteArray = voteList
    switch (sortMethod) {
      case VoteSortMethod.TVL:
        voteArray = voteArray.sort((a, b) => Number(b.tvl || 0) - Number(a.tvl || 0))
        break
      case VoteSortMethod.FEES:
        voteArray = voteArray.sort(
          (a, b) => Number(b.votetotalFeeRewardsUSD || 0) - Number(a.votetotalFeeRewardsUSD || 0)
        )
        break
      case VoteSortMethod.BRIBES:
        voteArray = voteArray.sort(
          (a, b) => Number(b.votetotalBribeRewardsUSD || 0) - Number(a.votetotalBribeRewardsUSD || 0)
        )
        break

      case VoteSortMethod.TOTAL_REWARDS:
        voteArray = voteArray.sort((a, b) => Number(b.votetotalRewardsUSD || 0) - Number(a.votetotalRewardsUSD || 0))
        break

      case VoteSortMethod.VAPR:
        voteArray = voteArray.sort((a, b) => Number(b.vAPR || 0) - Number(a.vAPR || 0))
        break

      case VoteSortMethod.MY_VOTE:
        voteArray = voteArray.sort(
          (a, b) => Number(b.poolWeightAmount?.toFixed() || 0) - Number(a.poolWeightAmount?.toFixed() || 0)
        )
        break
      case VoteSortMethod.TOTAL_VOTES:
        voteArray = voteArray.sort((a, b) => Number(b.weeklyVotedTotal || 0) - Number(a.weeklyVotedTotal || 0))
        break
    }
    return sortAscending ? voteArray.reverse() : voteArray
  }, [sortAscending, sortMethod, voteList])

  const filteredVote = useFilteredVote(sortData)

  return filteredVote
}

const useFilteredVote = (voteData: VoteDataProps[] | undefined) => {
  const filterString = useAtomValue(filterStringAtom)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!voteData) return undefined
    let returnDatas = voteData
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token) => {
        const allStrsymbol = token.token0.symbol + token.token1.symbol
        const allStrNAME = token.token0.name + token.token1.name
        const nameIncludesFilterString = allStrNAME.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString
      })
    }
    return returnDatas
  }, [lowercaseFilterString, voteData])
}
