import { Trans } from '@lingui/macro'
import { useMediaQuery } from '@mui/material'
import { ReactComponent as DOWN } from 'assets/svg/down.svg'
import { ReactComponent as DOWNBLOck } from 'assets/svg/downblock.svg'
import Column from 'components/Column'
import { RateToggle3 } from 'components/RateToggle'
import Row, { RowBetween } from 'components/Row'
import CurrencySearchModal from 'components/SearchModal2/CurrencySearchModal'
import ChartSection from 'components/Tokens/TokenDetails/ChartSection2'
import { QUERYDISPLAYS } from 'components/Tokens/TokenTable/TimeSelector'
import { PricePoint } from 'graphql/data/util'
import { ChartDataQueryProps, useChartDataQuery } from 'graphql/thegraph/ChartDataQuery'
import { useCurrency } from 'hooks/Tokens'
import { AllPoolsProps } from 'hooks/useQueryAllPools'
import { useAtom } from 'jotai'
import { pageTimePeriodAtom } from 'pages/TokenDetails'
import { useCallback, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { usePositionSwapActionHandlers, useTradeSWAPState } from 'state/positionSwap/hooks'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'
import { ThemedText } from 'theme/components/text'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { BN, formFeeWei, fromSqrt96Wei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { computeNumUnit } from 'utils/formatNum'

import { useDetailsPool } from './Hooks'

export const ChartInfoCard = styled(Box)`
  background: ${({ theme }) => theme.backgroundSurface};
  border-radius: 16px;
  fill: #fff;
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
`
const ColumnBox = styled(Column)`
  gap: 8px;
  align-items: flex-start;
  text-wrap: nowrap;
`

const OpenBox = styled(Row) <{ long?: string; short?: string }>`
  width: 100%;
  height: 2px;
  gap: 4px;
  > div {
    height: 2px;
  }
  .long {
    width: ${({ long }) => (Number(long) <= 0 ? '1' : long)}%;
    background: ${({ theme }) => theme.long};
  }
  .short {
    flex: 1;
    /* width: ${({ short }) => (Number(short) <= 0 ? '1' : short)}%; */
    background: ${({ theme }) => theme.tradeRed};
  }
`

const ChartBox = styled(Box)`
  padding: 0px 8px 8px;
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
  overflow: hidden;

  .charts {
    height: 436px;
    border-radius: 16px;
    background: rgba(24, 24, 24, 1);
  }
`

const CycleBox = styled(Box)``
function ChartInfo() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const { poolInfo, isToken0, INPUT, OUTPUT } = useTradeSWAPState()
  const [open, setopen] = useState(false)
  const handelonDismiss = () => {
    setopen(false)
  }

  const [chartPrice, setChartPrice] = useState<string | undefined>()

  const { onTradeInfo, onSetisToken0 } = usePositionSwapActionHandlers()
  const handelCurrencySelect = (data: AllPoolsProps) => {
    onTradeInfo(data)
  }

  const Dark = useIsDarkMode()
  const { traderFee, currency0, currency1, twapPrice,currentSqrtRatioX96 } = useDetailsPool()

  const currencyA = useCurrency(currency0?.address)
  const currencyB = useCurrency(currency1?.address)

  const BorrowRate = useMemo(() => {
    if (!traderFee) return
    const feeRate = isToken0 ? traderFee.fundingFee0 : traderFee.fundingFee1
    const fee = formFeeWei(feeRate)
    if (fee.isNaN()) {
      return 0
    }

    return fee.times(100).toFixed()
  }, [isToken0, traderFee])

  const [timePeriod, setTimePeriod] = useAtom(pageTimePeriodAtom)

  console.log({ poolInfo })
  const { data: tokenPriceQuery }: { data?: ChartDataQueryProps[] } = useChartDataQuery(
    poolInfo?.id,
    QUERYDISPLAYS[timePeriod]
  )

  const tokenPrice = useMemo(() => tokenPriceQuery?.[tokenPriceQuery.length - 1], [tokenPriceQuery])

  const token0Ratio = useMemo(() => {
    return fromSqrt96Wei(twapPrice?.toString(), currencyA?.decimals, currencyB?.decimals).toString()
  }, [currencyA?.decimals, currencyB?.decimals, twapPrice])


  const tokenSpotPrice=useMemo(() => {
    return fromSqrt96Wei(currentSqrtRatioX96?.toString(), currencyA?.decimals, currencyB?.decimals).toString()
  }, [currencyA?.decimals, currencyB?.decimals, currentSqrtRatioX96])


  const twapPriceFormat = useMemo(() => {
    if (isToken0) {
      return token0Ratio
    } else {
      return BN(1).div(token0Ratio).toString()
    }
  }, [isToken0, token0Ratio])

  const lastlyDate: ChartDataQueryProps[] | undefined = useMemo(() => {
    if (!tokenPrice || !tokenPriceQuery || !token0Ratio) return undefined
    const tempdate = {
      poolAddress: tokenPrice.poolAddress,
      durationId: tokenPrice.durationId,
      timestamp: Math.round(Number(new Date()) / 1000).toString(),
      value: tokenPrice.value,
      token0Ratio:  BN(1).div(tokenSpotPrice).toString() ,
      token1Ratio:tokenSpotPrice,
      token0DurationLow: tokenPrice.token0DurationLow,
      token0DurationHigh: tokenPrice.token0DurationHigh,
      token1DurationLow: tokenPrice.token1DurationLow,
      token1DurationHigh: tokenPrice.token1DurationHigh,
      token0Twap: BN(1).div(token0Ratio).toString(),
      token1Twap: token0Ratio,
    }

    return [...tokenPriceQuery, tempdate]
  }, [tokenPrice, tokenPriceQuery, token0Ratio,tokenSpotPrice])
  console.log({ lastlyDate })

  const priceHover = useCallback(
    (data: PricePoint) => {
      setChartPrice(`${twapPriceFormat}`)
    },
    [twapPriceFormat]
  )

  const openIValue = useMemo(() => {
    if (!poolInfo) return
    if (isToken0) {
      if (BN(poolInfo.sizeShort).eq(0)) return '100'
      return BN(poolInfo?.sizeLong).div(BN(poolInfo?.sizeLong).plus(poolInfo?.sizeShort)).multipliedBy(100).toString()
    } else {
      if (BN(poolInfo.sizeLong).eq(0)) return '100'
      return BN(poolInfo?.sizeShort).div(BN(poolInfo?.sizeLong).plus(poolInfo?.sizeShort)).multipliedBy(100).toString()
    }
  }, [isToken0, poolInfo])

  const openTokenValue = useMemo(() => {
    if (!poolInfo || !chartPrice) return
    if (isToken0) {
      return {
        longInset: computeNumUnit(
          BN(poolInfo?.sizeLong)
            .dividedBy(BN(10).pow(BN(poolInfo.token1.decimals)))
            .dividedBy(BN(chartPrice))
            .toString(),
          2
        ),
        shortInset: computeNumUnit(
          BN(poolInfo?.sizeShort)
            .dividedBy(BN(10).pow(BN(poolInfo.token1.decimals)))
            .dividedBy(BN(chartPrice))
            .toString(),
          2
        ),
      }
    } else {
      return {
        longInset: computeNumUnit(
          BN(poolInfo?.sizeShort)
            .dividedBy(BN(10).pow(BN(poolInfo.token1.decimals)))
            .toString(),
          2
        ),
        shortInset: computeNumUnit(
          BN(poolInfo?.sizeLong)
            .dividedBy(BN(10).pow(BN(poolInfo.token1.decimals)))
            .toString(),
          2
        ),
      }
    }
  }, [chartPrice, isToken0, poolInfo])

  const [isHoveredLong, setIsHoveredLong] = useState(false)
  const [isHoveredShort, setIsHoveredShort] = useState(false)
  return (
    <ChartInfoCard>
      <Box p="21px 24px 14px 16px">
        <Row gap="xmd" style={{ overflowX: isSmallScreen ? 'scroll' : 'auto' }}>
          <Row gap={isSmallScreen ? '20px' : '30px'} style={{}}>
            <ColumnBox>
              <Row onClick={() => setopen(true)} style={{ cursor: 'pointer' }}>
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {isToken0 ? poolInfo?.token0.symbol : poolInfo?.token1.symbol}
                </ThemedText.TextPrimary>
                <ThemedText.HeadNav054 fontSize={16}>
                  /{isToken0 ? poolInfo?.token1.symbol : poolInfo?.token0.symbol}
                </ThemedText.HeadNav054>
                {Dark ? <DOWNBLOck /> : <DOWN />}
              </Row>
              <ThemedText.TextGreen>{countZeros(chartPrice)}</ThemedText.TextGreen>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">24h high</ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {countZeros(isToken0 ? tokenPrice?.token1DurationHigh : tokenPrice?.token0DurationHigh)}
              </ThemedText.TextPrimary>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>24h low</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {countZeros(isToken0 ? tokenPrice?.token1DurationLow : tokenPrice?.token0DurationLow)}
              </ThemedText.TextPrimary>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>Open Interest</Trans>
              </ThemedText.BodySecondary>
              <Column width="100%">
                <RowBetween align="center">
                  <ThemedText.BodySecondary
                    width="100%"
                    style={{ cursor: 'pointer' }}
                    fontSize="12px"
                    color="long"
                    onMouseEnter={() => {
                      setIsHoveredLong(true)
                      setIsHoveredShort(true)
                    }}
                    onMouseLeave={() => {
                      setIsHoveredLong(false)
                      setIsHoveredShort(false)
                    }}
                  >
                    <Trans>
                      {isHoveredLong ? openTokenValue?.longInset || 0 : `${BN(openIValue || 0).toFixed(0)}%`}
                    </Trans>
                  </ThemedText.BodySecondary>
                  <ThemedText.BodySecondary
                    style={{ cursor: 'pointer' }}
                    fontSize="12px"
                    color="short"
                    onMouseEnter={() => {
                      setIsHoveredLong(true)
                      setIsHoveredShort(true)
                    }}
                    onMouseLeave={() => {
                      setIsHoveredLong(false)
                      setIsHoveredShort(false)
                    }}
                  >
                    <Trans>
                      {isHoveredShort
                        ? openTokenValue?.shortInset || 0
                        : `${BN(100)
                          .minus(openIValue || 0)
                          .toFixed(0)}%`}
                    </Trans>
                  </ThemedText.BodySecondary>
                </RowBetween>
                <OpenBox long={openIValue || '0'}>
                  <div className="long"></div>
                  <div className="short"></div>
                </OpenBox>
              </Column>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>Funding Rate</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>{BorrowRate || 0}% / 1h</ThemedText.TextPrimary>
            </ColumnBox>
          </Row>
          {currencyA && currencyB && (
            <RateToggle3
              currencyA={currencyA}
              currencyB={currencyB}
              isSorted={isToken0}
              handleRateToggle={() => {
                onSetisToken0(!isToken0)
              }}
            />
          )}
        </Row>
      </Box>

      <ChartBox>
        <Box className="charts">
          {/* <Rchart tokenPriceQuery={tokenPriceQuery} /> */}
          <ChartSection
            tokenPriceQuery={lastlyDate as any}
            priceHover={priceHover}
            onChangeTimePeriod={setTimePeriod}
          />
        </Box>
      </ChartBox>
      <CurrencySearchModal isOpen={open} onDismiss={handelonDismiss} onCurrencySelect={handelCurrencySelect} />
    </ChartInfoCard>
  )
}

export default ChartInfo
